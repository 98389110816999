import $ from 'jquery';
window.jQuery = $;
window.$ = $;

import 'fork-awesome/css/fork-awesome.min.css'
import 'bootstrap' // Bootstrap javascript
import 'bootstrap/dist/css/bootstrap.css' // Bootstrap css
import 'slick-carousel/slick/slick.js' // Slick-carousel javascript
import 'slick-carousel/slick/slick.css' // Slick css
import * as Sentry from '@sentry/browser' // Sentry

// Helpers setup
window.helpers.initSentry = Sentry.init